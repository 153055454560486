import {
    SiCplusplus,
    SiCss3,
    SiFigma,
    SiFramer,
    SiGithub, SiHtml5, SiJavascript, SiNeovim,
    SiNextdotjs,
    SiReact,
    SiRust,
    SiTailwindcss,
    SiTypescript,
    SiZig
} from "react-icons/si";
import {IconType} from "react-icons";

export type ProjectProps = {
  id: number;
  name: string;
  description: string;
  technologies: IconType[];
  techNames: string[];
  techLinks: string[];
  github: string;
  demo: string;
  image: string;
  available: boolean;
};

export const projects = [
    {
        id: 0,
        name: "Escape now",
        description:
            "Don't be tied down",
        technologies: [],
        techNames: [],
        techLinks: [],
        github: "https://github.com/nerkoux/incolnx",
        demo: "https://monitize-mastery.mini.site",
        image: "/projects/pepe.webp",
        available: true,
    },
    // Remove the script tag from the TypeScript code block
    // <script data-name="BMC-Widget" data-cfasync="false" src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" data-id="Trazhub" data-description="Support me on Buy me a Pizza" data-message="" data-color="#FF813F" data-position="Right" data-x_margin="18" data-y_margin="18"></script>

];
